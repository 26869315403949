<template>
  <v-layout v-if="getPermission('service:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <div v-if="errors.length" class="iv-custom-field pt-0 width-100">
        <div
          class="alert fade alert-danger"
          role="alert"
          v-bind:class="{ show: errors.length }"
        >
          <div v-for="(error, i) in errors" :key="i" class="alert-text">
            {{ error }}
          </div>
        </div>
      </div>
      <v-tabs
        v-model="assetTab"
        background-color="transparent"
        centered
        color="cyan"
        fixed-tabs
        grow
        class="custom-tab-transparent"
        hide-slider
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            v-if="getPermission(tab.permission + ':create') && tab.status"
            :key="index"
            :href="'#tab-' + tab.key"
          >
            <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="text-center">
          <template>
            <v-img
              :lazy-src="$defaultImage"
              :src="getProfileImage ? getProfileImage : $defaultImage"
              max-height="200"
              max-width="200"
              height="200px"
              width="200px"
              class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
            >
            </v-img>
            <div class="margin-auto">
              <v-btn
                class="my-4 custom-bold-button"
                outlined
                small
                v-on:click="selectImage"
                color="cyan"
                :loading="imageLoading"
                :disabled="imageLoading"
              >
                <template v-if="getProfileImage">Change Image</template>
                <template v-else>Upload Image</template>
              </v-btn>

              <div class="d-none">
                <v-file-input
                  ref="uploadImage"
                  v-on:change="updateProfileImage"
                  :rules="imageRules"
                  accept="image/png, image/jpeg, image/jpg"
                ></v-file-input>
              </div>
            </div>
          </template>
        </v-col>
        <v-col cols="7" md="7" sm="8">
          <v-tabs-items v-model="assetTab">
            <v-tab-item :value="'tab-overview'">
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow service-create-height"
                style="max-height: 80vh; position: relative"
              >
                <v-container fluid class="py-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Service Information
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <label class="font-weight-700 font-size-16 required"
                        >Name</label
                      >
                      <TextField
                        id="product-name"
                        dense
                        filled
                        placeholder="Name"
                        solo
                        flat
                        counter="50"
                        v-model.trim="productCreate.name"
                        color="cyan"
                        :maxlength="250"
                        :rules="[
                          validateRules.required(productCreate.name, 'Name'),
                        ]"
                      />

                      <v-layout class="pl-1">
                        <v-row>
                          <v-col lg="12" class="pb-0">
                            <label
                              class="pr-2 font-weight-bold font-size-16 width-100"
                              >Service No.
                            </label>
                          </v-col>
                          <v-col lg="2" class="py-0">
                            <label
                              class="pr-2 font-weight-700 font-size-16 width-100"
                              >Prefix
                            </label>
                            <v-text-field
                              id="prefix"
                              v-model.trim="productCreate.product_prefix"
                              dense
                              filled
                              placeholder="Prefix"
                              solo
                              flat
                              color="cyan"
                              class="width-100 pb-0"
                            ></v-text-field>
                          </v-col>
                          <v-col lg="4" class="py-0">
                            <label
                              class="pr-2 font-weight-700 font-size-16 width-100"
                              >No.</label
                            >
                            <v-text-field
                              type="number"
                              id="prefix_no"
                              v-model.trim="productCreate.product_prefix_no"
                              dense
                              filled
                              placeholder="Prefix no"
                              solo
                              flat
                              color="cyan"
                              class="width-100 pb-0"
                              :min="0"
                              :max="20"
                              v-on:keypress="isNumber($event)"
                            ></v-text-field>
                          </v-col>
                          <v-col lg="6" class="py-0">
                            <label
                              class="pr-2 font-weight-700 font-size-16 width-100"
                              >Preview</label
                            >
                            <div class="font-weight-bold font-size-16 mt-2">
                              {{ productCreate.product_prefix }}-{{
                                productCreate.product_prefix_no
                              }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-layout>
                      <v-layout class="pl-1">
                        <v-row>
                          <v-col lg="6" class="py-0">
                            <div class="d-flex justify-space-between">
                              <label
                                class="pr-2 font-weight-700 font-size-16 width-100"
                                >Brand
                              </label>
                              <v-icon small @click="BrandData">mdi-cog</v-icon>
                            </div>
                            <v-autocomplete
                              :items="brandList"
                              dense
                              filled
                              color="cyan"
                              item-color="cyan"
                              placeholder="Brand"
                              solo
                              flat
                              item-text="text"
                              item-value="text"
                              class="width-100"
                              v-model.trim="productCreate.brand"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Brand(s) Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col lg="6" class="py-0">
                            <div class="d-flex justify-space-between">
                              <label
                                class="pr-2 font-weight-700 font-size-16 width-100"
                                >Category
                              </label>
                              <v-icon @click="CategoryData" small
                                >mdi-cog</v-icon
                              >
                            </div>
                            <v-autocomplete
                              dense
                              filled
                              color="cyan"
                              item-color="cyan"
                              :items="categoryList"
                              placeholder="Category"
                              solo
                              flat
                              item-text="text"
                              item-value="text"
                              class="width-100"
                              v-model.trim="productCreate.category"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Category(s) Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <v-layout class="pl-1">
                        <v-row>
                          <v-col lg="6" class="py-0">
                            <label
                              class="pr-2 font-weight-700 font-size-16 width-100"
                              >Specification</label
                            >
                            <v-text-field
                              id="specification"
                              v-model.trim="productCreate.specification"
                              dense
                              filled
                              placeholder="Specification"
                              solo
                              flat
                              color="cyan"
                              class="width-100"
                            ></v-text-field>
                          </v-col>
                          <v-col lg="6" class="py-0">
                            <label
                              class="pr-2 font-weight-700 font-size-16 width-100"
                              >Tags</label
                            >
                            <TagAutoComplete
                              type="service"
                              v-model="productCreate.tags"
                            />
                          </v-col>
                        </v-row>
                      </v-layout>
                      <v-layout class="pl-1">
                        <v-flex md6>
                          <div class="d-flex justify-content-between">
                            <label
                              class="font-weight-700 font-size-16 width-100 required"
                              >UOM
                            </label>
                            <v-icon small @click="UomData">mdi-cog</v-icon>
                          </div>
                          <v-autocomplete
                            dense
                            filled
                            color="cyan"
                            item-color="cyan"
                            :items="unitList"
                            placeholder="UOM"
                            solo
                            flat
                            item-text="text"
                            item-value="text"
                            class="width-100"
                            v-model="productCreate.unit"
                            :rules="[
                              validateRules.required(productCreate.unit, 'UOM'),
                            ]"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Unit(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </v-flex>
                        <ManageServiceUnit
                          :u-dialog="serviceUnitDialog"
                          :unit="unitList"
                          v-on:close-dialog="serviceUnitDialog = false"
                          v-on:get-product-unit="getOptions"
                        >
                        </ManageServiceUnit>
                      </v-layout>
                      <label class="font-weight-700 pl-1 font-size-16"
                        >Service Description</label
                      >
                      <v-textarea
                        class="pl-1"
                        hide-details
                        dense
                        filled
                        placeholder="Description"
                        solo
                        flat
                        row-height="20"
                        auto-grow
                        color="cyan"
                        v-model.trim="productCreate.description"
                        :rules="[
                          validateRules.minLength(
                            productCreate.description,
                            'Name',
                            1
                          ),
                          validateRules.maxLength(
                            productCreate.description,
                            'Name',
                            1024
                          ),
                        ]"
                        v-on:keypress="(e) => manageLimitdescr(e)"
                        v-on:paste="(e) => onPaste(e)"
                      ></v-textarea>
                      <v-col md="12" class="text-right pt-0"
                        >{{
                          productCreate.description
                            ? productCreate.description.length
                            : 0
                        }}/200</v-col
                      >

                      <v-layout v-if="false">
                        <v-flex md6>
                          <label class="font-weight-700 pl-2 font-size-16"
                            >Reference Number
                          </label>
                          <v-text-field
                            dense
                            filled
                            color="cyan"
                            placeholder="Reference Number"
                            solo
                            flat
                            class="pl-2 width-100"
                            v-model.trim="productCreate.ref_number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex md6>
                          <label class="font-weight-700 pl-2 font-size-16"
                            >Internal Service Number
                          </label>
                          <v-text-field
                            dense
                            filled
                            color="cyan"
                            placeholder="Internal Service Number"
                            solo
                            flat
                            class="pl-2 width-100"
                            v-model.trim="productCreate.int_service_number"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>

                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Pricing Information
                      </h3>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="white--text mr-2"
                        depressed
                        color="cyan"
                        tile
                        @click="addPrice()"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        Add New Price
                      </v-btn>
                    </v-card-title>
                    <v-card-text
                      style="padding-left: 14px !important"
                      class="font-size-16"
                    >
                      <v-col md="12" class="py-0 px-0">
                        <div
                          class="middle-header-background d-flex align-center"
                        >
                          <p class="mb-0">
                            Price Information<br />
                            <span>
                              <v-icon small>mdi-progress-question</v-icon>
                              Specify the unit price, selling cost price details
                              for your service</span
                            >
                          </p>
                        </div>
                      </v-col>

                      <v-col
                        md="12"
                        v-if="
                          productCreate &&
                          productCreate.priceList &&
                          productCreate.priceList.length
                        "
                        class="px-0"
                      >
                        <v-row class="mt-1">
                          <v-col class="py-0">
                            <label class="btx-label mt-2 required"
                              >Selling Price/Unit Price</label
                            >
                          </v-col>
                          <v-col class="py-0">
                            <label class="btx-label mt-2">Start Date</label>
                          </v-col>
                          <v-col class="py-0">
                            <label class="btx-label mt-2">End Date</label>
                          </v-col>
                        </v-row>
                        <template>
                          <v-row
                            v-for="(row, index) in productCreate.priceList"
                            :key="index"
                            class="mb-0"
                          >
                            <v-col cols="4" lg="4" class="py-0">
                              <v-text-field
                                :min="0"
                                oninput="if(this.value < 0) this.value = 0;"
                                type="number"
                                solo
                                flat
                                dense
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                placeholder="Selling Price/Unit Price"
                                prepend-inner-icon="mdi-currency-usd"
                                v-model="row.selling_cost"
                                @keyup="discountPriceChange(index)"
                                v-on:change="discountSelling(index)"
                                :rules="[
                                  validateRules.required(
                                    row.selling_cost,
                                    'Selling Price/Unit Price'
                                  ),
                                ]"
                                class="mt-0"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" lg="4" class="py-0">
                              <DatePicker
                                class="pr-0"
                                hide-details
                                hide-top-margin
                                :disabled="
                                  isStartDateDisable(index) || pageLoading
                                "
                                :loading="pageLoading"
                                placeholder="Start Date"
                                :min-date="end_min_date(row.start_date, index)"
                                v-model="row.start_date"
                                :rules="[
                                  validateRules.required(
                                    row.start_date,
                                    'Start Date'
                                  ),
                                ]"
                              >
                              </DatePicker>
                            </v-col>
                            <v-col
                              cols="4"
                              lg="4"
                              class="py-0 d-flex justify-space-between"
                            >
                              <div>
                                <template v-if="row.end_date">
                                  <DatePicker
                                    hide-details
                                    readonly
                                    :loading="pageLoading"
                                    :disabled="true"
                                    hide-top-margin
                                    placeholder="End Date"
                                    v-model="row.end_date"
                                  >
                                  </DatePicker>
                                </template>
                                <em v-else class="text-muted"> no end date </em>
                              </div>
                              <v-btn
                                v-if="
                                  productCreate.priceList.length == index + 1
                                "
                                class="white--text"
                                depressed
                                color="red darken-4"
                                tile
                                @click="removePrice(index, row)"
                                min-width="30"
                                max-width="30"
                                width="30"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                      <v-col md="12" class="py-0">
                        <hr class="mt-0" />
                      </v-col>
                      <v-row>
                        <v-col cols="4" lg="4" class="py-0">
                          <label class="font-weight-700 font-size-16 width-100"
                            >Has Custom Tax?</label
                          >
                        </v-col>
                        <v-col
                          cols="4"
                          lg="4"
                          class="py-0"
                          v-if="productCreate.custom_tax"
                        >
                          <label
                            class="font-weight-700 font-size-16 width-100 d-flex justify-space-between"
                            >Tax Name
                            <!-- <v-icon small>mdi-cog</v-icon> -->
                          </label>
                        </v-col>

                        <v-col
                          cols="4"
                          lg="4"
                          class="py-0"
                          v-if="productCreate.custom_tax"
                        >
                          <label class="font-weight-700 font-size-16 width-100"
                            >Tax Rate (%)</label
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" lg="4" class="py-0">
                          <v-autocomplete
                            :items="customTaxList"
                            dense
                            filled
                            color="cyan"
                            item-color="cyan"
                            placeholder="Custom Tax"
                            solo
                            flat
                            item-text="text"
                            item-value="value"
                            class="width-100"
                            v-model.trim="productCreate.custom_tax"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Custom Tax(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="4"
                          lg="4"
                          class="py-0"
                          v-if="productCreate.custom_tax"
                        >
                          <v-autocomplete
                            :items="taxNameList"
                            dense
                            filled
                            color="cyan"
                            item-color="cyan"
                            placeholder="Tax Name"
                            solo
                            flat
                            item-text="text"
                            item-value="value"
                            class="width-100"
                            v-model.trim="productCreate.tax_name"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Tax name(s) Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="4"
                          lg="4"
                          class="py-0"
                          v-if="productCreate.custom_tax"
                        >
                          <v-text-field
                            dense
                            filled
                            placeholder="Tax Rate "
                            solo
                            flat
                            class="width-100"
                            color="cyan"
                            v-model.trim="productCreate.tax_rate"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <br />
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-0"
                    v-if="show_skills == 1"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Skills , Licence & Certs Information
                      </h3>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="white--text mr-2"
                        depressed
                        color="cyan"
                        tile
                        @click="addCert_dialog = true"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        Add
                      </v-btn>
                    </v-card-title>
                    <v-card-text style="padding-left: 14px !important">
                      <div class="pl-2" style="background: whitesmoke">
                        <v-row>
                          <v-col cols="2" lg="2" class="py-0 pl-1">
                            <label
                              class="font-weight-700 font-size-16 width-100 mt-1"
                              >Color</label
                            >
                          </v-col>
                          <v-col cols="4" lg="4" class="py-0 pl-1">
                            <label
                              class="font-weight-700 font-size-16 width-100 mt-1"
                              >Name</label
                            >
                          </v-col>
                          <v-col cols="4" lg="4" class="py-0 pl-0">
                            <label
                              class="font-weight-700 font-size-16 width-100 mt-1"
                              >Type</label
                            >
                          </v-col>
                          <v-col cols="2" lg="2" class="py-0 pl-1"></v-col>
                        </v-row>
                      </div>
                      <template>
                        <div v-if="productCreate?.skills_carts?.length > 0">
                          <v-row
                            v-for="(row, index) in productCreate.skills_carts"
                            :key="index"
                            class="mb-0 mx-1 alternate-listing-row-div"
                            style="border-bottom: 1px solid #ddd7d7"
                          >
                            <v-col cols="2" lg="2" class="py-0 my-auto">
                              <v-chip
                                small
                                :color="row.color"
                                variant="flat"
                              ></v-chip>
                            </v-col>
                            <v-col cols="4" lg="4" class="py-0 pl-1 my-auto">
                              {{ row.name }}
                            </v-col>
                            <v-col cols="4" lg="4" class="py-0 pl-1 my-auto">
                              {{ row.type }}
                            </v-col>
                            <v-col cols="2" lg="2" class="py-0 my-auto">
                              <v-btn
                                icon
                                class="white--text"
                                depressed
                                color="red darken-4"
                                @click="removeCert(index, row)"
                                min-width="25"
                                max-width="25"
                                width="25"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                              style="width: 30px"
                            />
                            Uhh... There are no skills , licence & certs at the
                            moment.
                          </p>
                        </div>
                      </template>
                    </v-card-text>
                  </v-card>
                  <br />

                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius"
                    v-if="false"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Service Images
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <div v-if="getPermission('attachment:create')">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Images
                          <!-- <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span>More than 5 images are not allowed</span>
                  </v-tooltip> -->
                        </label>
                        <div>
                          <FileTemplate
                            allowUpload
                            isMinDisplay
                            :attachments="productCreate.product_images"
                            v-on:file:updated="updateImages"
                            :file-type="true"
                            acceptFile="image/png, image/jpeg, image/jpg"
                          >
                          </FileTemplate>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <!-- Custom Form Data -->
                  <template v-if="!productId">
                    <v-row>
                      <CustomForm :module="'service'" :module-id="productId" />
                    </v-row>
                    <br />
                  </template>
                  <!-- Custom Form Data End -->
                </v-container>
              </perfect-scrollbar>
            </v-tab-item>

            <v-tab-item :value="'tab-attachments'">
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow service-create-height"
                style="max-height: 80vh; position: relative"
              >
                <v-col md="12" class="pb-0">
                  <DocumnetList
                    v-model="productCreate.files"
                    :document-type="docType"
                  >
                  </DocumnetList>
                </v-col>
              </perfect-scrollbar>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageCategory
      service
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageSkill
      service
      :c-dialog="manageSkillDialog"
      :skill="skillCertificateList"
      v-on:close-dialog="manageSkillDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageSkill>
    <ManageLicense
      service
      :c-dialog="manageLicenseDialog"
      :license="licenseCertificateList"
      v-on:close-dialog="manageLicenseDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageLicense>
    <AddCertisDialog
      :product-type="product_type"
      :related-id="productId"
      :product-dialog="addCert_dialog"
      v-on:update-values="addSkills"
      v-on:close="addCert_dialog = false"
      :updated-data="productCreate.skills_carts"
    >
    </AddCertisDialog>
  </v-layout>
</template>

<script>
import AddCertisDialog from "@/view/components/AddCertisDialog.vue";
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageSkill from "@/view/pages/partials/Manage-Product-Skill.vue";
import ManageLicense from "@/view/pages/partials/Manage-Product-License.vue";
import ManageServiceUnit from "@/view/pages/partials/Manage-Service-Unit.vue";
//import AttachFile from "@/view/components/AttachFile";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import DocumnetList from "@/view/pages/DocumnetList";
import {
  QUERY,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import { map, compact } from "lodash";
//import FileUpload from "@/view/components/app-component/FileInput.vue";
import moment from "moment";
import { cloneDeep } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";

export default {
  name: "product-create",
  mixins: [
    CommonMixin,
    WarrantyMixin,
    ProductMixin,
    ValidationMixin,
    FileManagerMixin,
  ],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  data() {
    return {
      docType: 10,
      product_type: null,
      dialog_key: Number(new Date()),
      manageBrandDialog: false,
      assetTab: "tab-overview",
      addCert_dialog: false,
      imageLoading: false,
      pageLoading: false,
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      skillCertificateList: new Array(),
      licenseCertificateList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      serviceUnitDialog: false,
      unitList: new Array(),
      barcode: null,
      manageCategoryDialog: false,
      manageSkillDialog: false,
      show_skills: 0,
      show_licence: 0,
      manageLicenseDialog: false,
      taxNameList: [],
      warrantyActive: false,
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "customer",
          status: true,
        },
        {
          title: "Attachments",
          icon: "mdi-file",
          key: "attachments",
          permission: "customer",
          status: true,
        },
      ],
      productCreate: {
        name: null,
        product_prefix: null,
        product_prefix_no: null,
        tags: null,
        specification: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        part_number: null,
        hsn_code: null,
        description: null,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        license: [],
        skill: [],
        unit: "unit",
        tax: null,
        tax_exemption: null,
        accounting: "none",
        int_service_number: null,
        ref_number: null,
        product_type: "service",
        purchase_cost: null,
        selling_cost: null,
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        firstTime: true,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        cost: null,
        images: [],
        service_image: null,
        product_images: [],
        product_atach: null,
        tax_rate: null,
        tax_name: null,
        custom_tax: 1,
        skills_carts: [],
        priceList: [
          {
            id: null,
            unit_price: 0,
            selling_price: 0,
            discount_price: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: null,
          },
        ],
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
        files: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
      },
      customTaxList: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
      asset_image: null,
    };
  },
  components: {
    AddCertisDialog,
    FileTemplate,
    ManageCategory,
    ManageServiceUnit,
    ManageBrand,
    // FileUpload,
    //AttachFile,
    DatePicker,
    TagAutoComplete,
    ManageSkill,
    ManageLicense,
    CustomForm,
    DocumnetList,
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
      // console.log(this.barcode)
    },
    productCreateData: {
      deep: true,
      //immediate: true,
      handler(param) {
        //console.log(this.unitList);
        this.productCreate = param;
        if (this.lodash.isObject(this.productCreate.unit)) {
          this.productCreate.unit = this.productCreate.unit
            ? this.productCreate.unit
            : "unit";
          this.productCreate.barcode = this.barcode;

          const files = map(this.productCreate.files, (row) => {
            return { ...row };
          });
          if (files.length) {
            this.productCreate.attachment = files;
          }
        }

        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = true;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (this.$route && this.$route.query && this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }

        this.$nextTick(() => {
          if (param.warranty && param.warranty.length) {
            const item = compact(map(param.warranty, "id"));
            if (item && item.length) {
              this.warrantyActive = true;
            }
          }
        });
      },
    },
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          let obj = _this.unitList.find(
            (o) => o.text === _this.productCreate.unit
          );
          if (!obj) {
            _this.productCreate.unit = "unit";
          }

          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);

        // this.productCreate.barcode = this.barcode;
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    addSkills(data) {
      let selectedCarts = data;
      this.productCreate.skills_carts = selectedCarts;
      /*  selectedCarts.map((row) => {
         this.productCreate.skills_carts.push(row);
           }
       ); */
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (
        this.productCreate &&
        this.productCreate.priceList &&
        this.productCreate.priceList.length < 1
      ) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.productCreate.priceList.push({
        id: null,
        unit_price: 0,
        selling_cost: 0,
        discount_price: 0,
        start_date: previous_end_date,
        end_date: null,
      });
    },
    removePrice(index) {
      if (index >= 1) {
        this.productCreate.priceList[index - 1].end_date = null;
      }
      this.productCreate.priceList.splice(index, 1);
    },
    discountPriceChange(index) {
      if (
        this.productCreate.priceList[index].discount_price >
        this.productCreate.priceList[index].selling_cost
      ) {
        this.productCreate.priceList[index].discount_price =
          this.productCreate.priceList[index].selling_cost;
      }
    },
    discountSelling(index) {
      if (
        this.productCreate.priceList[index].selling_cost <
        this.productCreate.priceList[index].discount_price
      ) {
        this.productCreate.priceList[index].selling_cost =
          this.productCreate.priceList[index].discount_price;
      }
    },
    // addCertSkill() {
    //   this.productCreate.certList.push({
    //     id: null,
    //     cert_name: null,
    //     cert_type: null,
    //   });
    // },
    removeCert(index) {
      this.productCreate.skills_carts.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          _this.asset_image =
            data && data.file && data.file.url ? data.file.url : null;
          /*  _this.productCreate.product_atach =
            data && data.file && data.file.url ? data.file.url : null; */
          _this.productCreate.images = [data.id];
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.productCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.productCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.productCreate.description &&
        this.productCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.serviceUnitDialog = true;
      this.getOptions();
    },
    CategoryData() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    SkillData() {
      this.manageSkillDialog = true;
      //this.getOptions();
    },
    LicenseData() {
      this.manageLicenseDialog = true;
      this.getOptions();
    },
    updateImages(param) {
      this.productCreate.images = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          if (_this.lodash.isEmpty(response.data) === false) {
            this.show_skills = response.data.skills_cert_lecn_show;
            /*   this.show_licence = response.data.show_licence.value; */
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax_names
          ) {
            _this.taxNameList = response.data.tax_names;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.licenseCertificate
          ) {
            _this.licenseCertificateList = response.data.licenseCertificate;
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.skillCertificate
          ) {
            _this.skillCertificateList = response.data.skillCertificate;
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.supplier
          ) {
            _this.supplierList = response.data.supplier;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //UOM when deleted
            let obj = _this.unitList.find(
              (o) => o.text === _this.productCreate.unit
            );
            if (!obj) {
              _this.productCreate.unit = null;
            }
          }
          if (
            !_this.productId &&
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode
          ) {
            _this.barcode = response.data.barcode;
            _this.productCreate.barcode = response.data.barcode;
          }

          /* if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
            _this.barcodeSetting = response.data.barcode_setting;
            _this.productCreate.description = _this.barcodeSetting.description;
          } */
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
  computed: {
    isStartDateDisable: () => {
      return function (index) {
        if (this.productCreate.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.productCreate.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.productCreate.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },
    getProfileImage() {
      return this.asset_image
        ? this.asset_image
        : this.productCreate?.service_image?.file?.url;
    },
    /* getProfileImage() {
      return this.productCreate.product_atach && !this.productId
        ? this.productCreate.product_atach
        : this.productCreateData?.product_images?.file?.url;
    }, */
  },
};
</script>
<style scoped>
/* tabs css  */

.custom-tab-transparent.v-tabs--fixed-tabs > .v-tabs-bar .v-tab.v-tab--active {
  background-color: #00bcd4 !important;
  color: #fff !important;
  overflow: hidden;
}
.custom-tab-transparent.v-tabs--fixed-tabs
  > .v-tabs-bar
  .v-tab.v-tab--active
  .grey--text.text--darken-4 {
  color: #fff !important;
  caret-color: #fff !important;
}

.custom-tab-transparent.v-tabs .v-tab {
  background: #00bcd429;
  width: 25%;
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab.v-tab:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + -0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
